import { Space } from "./styles";

export type SizeType = "0" | "min" | "XS" | "S" | "M" | "L" | "XL";

type SpacerProps = {
  size?: SizeType;
};
const Spacer: React.FC<SpacerProps> = ({ size = "0" }) => {
  return <Space $size={size} />;
};
export default Spacer;
