import styled from "styled-components";
import { SizeType } from "./Spacer";

export const Space = styled.div<{
  $size?: SizeType;
}>`
  box-sizing: border-box;
  width: 100%;
  height: ${({ $size }) => {
    switch ($size) {
      case "0":
        return "0px";
      case "min":
        return "8px";
      case "XS":
        return "24px";
      case "S":
        return "40px";
      case "M":
        return "60px";
      case "L":
        return "120px";
      case "XL":
        return "180px";
      default:
        return "0px";
    }
  }};

  ${({ theme }) => theme.mediaQueries.laptop} {
    height: ${({ $size }) => {
      switch ($size) {
        case "0":
          return "0px";
        case "min":
          return "8px";
        case "XS":
          return "24px";
        case "S":
          return "40px";
        case "M":
          return "80px";
        case "L":
          return "160px";
        case "XL":
          return "240px";
        default:
          return "0px";
      }
    }};
  }
`;
